import Layout from '@/layout'

export const operateDashboardRoutes = [
  {
    path: '/operate-dashboard',
    component: Layout,
    name: 'operateDashboard',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '營運報表模組',
    },
    children: [
      {
        path: '/operate-overview',
        name: 'operateOverview',
        component: () =>
          import(
            '@/views/OperateDashboard/OperateOverview/OperateOverview.vue'
          ),
        meta: {
          title: '會員數據總覽',
          action: 'admin.businessDataChart.memberPage',
        },
      },
      {
        path: '/member-summary',
        name: 'MemberSummary',
        component: () =>
          import('@/views/OperateDashboard/MemberSummary/MemberSummary.vue'),
        meta: {
          title: '會員輪廓',
          action: 'admin.businessDataChart.memberPage',
        },
      },
      {
        path: 'member-contribution',
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '會員貢獻',
        },
        children: [
          {
            path: 'member-contribution-overview',
            name: 'memberContributionOverview',
            component: () =>
              import(
                '@/views/OperateDashboard/MemberContributionOverview/MemberContributionOverview.vue'
              ),
            meta: {
              title: '會員貢獻總覽',
              action: 'admin.businessDataChart.memberPage',
            },
          },
          {
            path: 'first-purchase-overview',
            name: 'FirstPurchaseOverview',
            component: () =>
              import(
                '@/views/OperateDashboard/FirstPurchaseOverview/FirstPurchaseOverview.vue'
              ),
            meta: {
              title: '首購回購客',
              action: 'admin.businessDataChart.memberPage',
            },
          },
          {
            path: 'repurchase-overview',
            name: 'RepurchaseOverview',
            hidden: true,
            component: () =>
              import(
                '@/views/OperateDashboard/RepurchaseOverview/RepurchaseOverview.vue'
              ),
            meta: {
              title: '首購回購客',
              action: 'admin.businessDataChart.memberPage',
            },
          },
          {
            path: 'member-age-overview',
            name: 'MemberAgeOverview',
            component: () =>
              import(
                '@/views/OperateDashboard/MemberAgeOverview/MemberAgeOverview.vue'
              ),
            meta: {
              title: '年齡',
              action: 'admin.businessDataChart.memberPage',
            },
          },
          {
            path: 'member-gander-overview',
            name: 'MemberGanderOverview',
            component: () =>
              import(
                '@/views/OperateDashboard/MemberGanderOverview/MemberGanderOverview.vue'
              ),
            meta: {
              title: '性別',
              action: 'admin.businessDataChart.memberPage',
            },
          },
        ],
      },
    ],
  },
]
