export default [
  {
    path: '/homepage',
    name: 'HomePage',
    component: () => import('@/views/HomePage/HomePage'),
    hidden: true,
    meta: {
      // action: 'admin.lineConfig.page',
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/HomePage/components/FAQList.vue'),
    hidden: true,
    meta: {
      // action: 'admin.lineConfig.page',
    },
  },
  {
    path: '/announcement',
    name: 'AnnouncementList',
    component: () => import('@/views/HomePage/components/AnnouncementList.vue'),
    hidden: true,
    meta: {
      // action: 'admin.lineConfig.page',
    },
  },
  {
    path: '/announcement/detail/:id',
    name: 'AnnouncementDetail',
    component: () => import('@/views/HomePage/components/AnnouncementDetail.vue'),
    hidden: true,
    meta: {
      // action: 'admin.lineConfig.page',
    },
  },
  {
    path: '/handbook-list',
    name: 'HandbookList',
    component: () => import('@/views/HomePage/components/HandbookList.vue'),
    hidden: true,
    meta: {
      // action: 'admin.lineConfig.page',
    },
  },
]
