<template>
  <svg :class="svgClass" aria-hidden="true" v-bind="$attrs" :width="width" :height="height">
    <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: 'var(--primary)',
    },
  },
  computed: {
    iconName () {
      return `#${this.icon}`
    },
    svgClass () {
      if (this.className) {
        return `za-svg-icon za-icon-${this.icon} ` + this.className
      } else {
        return `za-svg-icon za-icon-${this.icon}`
      }
    },
  },
})
</script>

    <style lang="scss">
    .za-svg-icon {
      vertical-align: middle;
      // fill: currentColor;
      overflow: hidden;
    }
    </style>
