import { get } from 'lodash'

const getters = {
  appTheme: (state) => state.app.appTheme,
  // ---- user
  user: (state) => state.user.user,
  userName: (state) => state.user.userName,
  role: (state) => state.user.role,
  orgId: (state) => state.user.user.OrgId,
  shop: (state) => state.user.shop,
  shopPlanIsReady: (state) => get(state.permission, 'shopPlanIsReady') && state.user.shop,
  shopName: (state) => {
    const shop = state.user.allowShop.find((s) => s.id === state.user.shop)
    return shop ? shop.name : '請選擇區域'
  },
  userAllowShop: (state) => state.user.allowShop,
  userPlanFeature: (state) => state.user.planFeature,
  userPlanLimit: (state) => state.user.planLimit,

  userFeatures: (state) => state.user.features,
  // ---- token
  token: (state) => state.auth.token,
  // ---- app
  sidebar: (state) => state.app.sidebar,
  sidebarPage: (state) => state.app.sidebarPage,
  // ---- permission
  permissionRoutes: (state) => state.permission.routes,
  orgAdminRoutes: (state) => state.permission.orgRoutes,
  asyncRoutes: (state) => state.permission.addRoutes,
  shopPlanFeatures: (state) => get(state.permission, 'raw.shopPlanFeatures'),
  shopPlanLimits: (state) => get(state.permission, 'raw.shopPlanLimits'),
  shopUserFeatures: (state) => get(state.permission, 'raw.userFeatures'),

  originShopPlanFeatures: (state) => get(state.permission, 'raw.shopPlanFeatures'),
  originShopUserFeatures: (state) => get(state.permission, 'raw.userFeatures'),
  originShopPlanLimits: (state) => get(state.permission, 'raw.shopPlanLimits'),
  computedShopPlanFeatures: (state) => get(state.permission, 'computed.shopPlanFeatures'),
  computedShopUserFeatures: (state) => get(state.permission, 'computed.userFeatures'),
  computedShopPlanLimits: (state) => get(state.permission, 'computed.shopPlanLimits'),
  computedShopMarketingPermission: (state) => get(state.permission, 'marketingItem'),
  // ---- shop
  shopCount: (state) => state.shop.count,
  shopList: (state) => state.shop.list,
  shopTimeUnit: (state) => state.shop.timeUnit,
  // ---- ecommerce homepage
  ecommerceHomePageConfig: (state) => state['ecommerce-home'].homePageConfig,
  activitys: (state) => state['ecommerce-home'].activitys,
  categorys: (state) => state['ecommerce-home'].categorys,
}
export default getters
