import { pageActionRecord } from '@/api/bob'

class BatchCreate {
  constructor (model, {
    maxCreateInterval = 5000,
    checkInterval = 500,
    maxCount = 5,
  } = {}) {
    this.waitCreate = []
    this.model = model
    this.maxCreateInterval = maxCreateInterval
    this.checkInterval = checkInterval
    this.lastAddCreate = Date.now()
    this.maxCount = maxCount
  }

  create (data) {
    this.waitCreate.push(data)
    this.lastAddCreate = Date.now()
  }

  start () {
    this.interval = setInterval(async () => {
      while (
        this.waitCreate.length > (this.maxCount - 1) ||
        (this.waitCreate.length > 0 && ((Date.now() - this.lastAddCreate) > this.maxCreateInterval))
      ) {
        const count = Math.min(this.waitCreate.length, this.maxCount)
        const dataSet = this.waitCreate.splice(0, count)
        if (dataSet.length) {
          pageActionRecord({ data: dataSet })
          // dataSet.forEach((item) => {

          // })
        }
        // await this.model.batchPut(data)
      }
    }, this.checkInterval)
  }
}

export const batch = new BatchCreate()
batch.start()
export default BatchCreate

// import PQueue from 'p-queue'
// // const PQueue = require('p-queue')

// console.log(PQueue)
// // import delay from 'delay'

// let lock = false
// export const tasks = []
// const queue = new PQueue()

// // const task = async () => console.log(await delay(500, { value: '🦄' }))

// // for (let i = 0; i < 20; i++) {
// //   tasks.push(task)
// // }

// // queue.add(task)
// // queue.start()

// queue.on('idle', () => {
//   if (lock && tasks.length) {
//     setTimeout(() => {
//       queue.add(() => Promise.resolve('unlock'))
//     }, 3000)
//   }
// })

// queue.on('completed', (res) => {
//   if (res === 'unlock') lock = false
//   if (!lock && tasks.length) {
//     lock = true
//     for (let i = 0; i < 5; i++) {
//       queue.add(tasks.shift())
//     }
//   }
// })

// export default queue
