import Layout from '@/layout'

export const rebateRoutes = [
  // -- 儲值金模組
  {
    path: '/rebate',
    component: Layout,
    name: 'Rebate',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '回饋金模組',
    },
    children: [
      {
        path: '/rebate-report',
        name: 'RebateReport',
        component: () => import('@/views/Rebate/RebateReport/RebateReport.vue'),
        meta: {
          title: '回饋金報表',
          action: 'admin.shopCashbackReport.page',
        },
      },
    ],
  },
]
