const formUtils = {
  checkForm: (form) => {
    return new Promise((resolve) => {
      form.validate((valid) => {
        if (valid) resolve(true)
        if (!valid) resolve(false)
      })
    })
  },

  resetForm: (form) => {
    form.resetFields()
  },

  clearValidate: (form) => {
    form.clearValidate()
  },
}

export default formUtils
