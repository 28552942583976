<template>
  <div class="dialog-title-with-hint">
    <p class="title">{{ title }}</p>
    <p class="hint">{{ hint }}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleWithHint',
  props: ['title', 'hint'],
}
</script>

<style lang="postcss" scoped>
.title {
  @apply text-primary-100 text-[18px] leading-[21.6px] font-bold mb-[6px];
}

.hint {
  @apply text-gray-80 text-sm leading-[14.4px] font-medium;
}
</style>
