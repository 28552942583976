import Layout from '@/layout'

export const marketLeagueRoutes = [
  // 聯盟行銷模組
  {
    path: '/marketing-league',
    name: 'Marketing League',
    component: Layout,
    isGroup: true,
    meta: { title: '聯盟行銷模組' },
    children: [
      {
        path: 'settings',
        name: 'Marketing League Settings',
        component: () =>
          import('@/views/MarketingLeague/MarketingLeagueSettings'),
        meta: {
          title: '聯盟行銷活動管理',
          action: 'admin.allianceActivity.page',
        },
      },
      {
        path: 'issuance-record',
        name: 'Marketing League Issuance Record',
        component: () =>
          import('@/views/MarketingLeague/MarketingLeagueIssuanceRecord'),
        meta: {
          title: '聯盟行銷發放列表',
          action: 'admin.allianceActivity.page',
        },
      },
      {
        path: 'usage-record',
        name: 'Marketing League Usage Record',
        component: () =>
          import('@/views/MarketingLeague/MarketingLeagueUsageRecord'),
        meta: {
          title: '聯盟行銷使用列表',
          action: 'admin.allianceActivity.page',
        },
      },
    ],
  },
]
