import { admin2 } from '@/api/instance'

export const CreateCustomizePage = async ({
  shopId,
  title,
  content,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommercePage/`,
    data: {
      title,
      content,
    },
  })
  return res
}

// => 更新頁面設定
export const UpdatePageSetting = async ({
  shopId,
  key,
  title,
  content,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommercePage/${key}`,
    data: {
      title,
      content,
    },
  })
  return res
}

export const DeletePageSetting = async ({
  shopId,
  key,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommercePage/${key}`,
  })
  return res
}

export const GetPageSetting = async ({
  shopId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePage`,
  })
  return res
}

export const FindPageSetting = async ({
  shopId,
  key,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePage/${key}`,
  })
  return res
}

export const GetLinkMeta = async ({
  shopId,
  link,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePage/getLinkMeta`,
    params: {
      link,
    },
  })
  return res
}
