import Layout from '@/layout'

export const lotteryRoutes = [
  // 會員抽獎模組
  {
    path: '/lottery',
    component: Layout,
    name: 'Lottery',
    // hidden: !(configuration('ecommerceModule') === 'true'),
    // hidden: true,
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '會員抽獎模組',
    },
    children: [
      {
        path: '/reward-content-setting',
        name: 'RewardContentSetting',
        component: () => import('@/views/Lottery/RewardContentSetting'),
        meta: {
          title: '抽獎內容設定',
          action: 'admin.memberGame.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBERGAME_LIST',
        },
      },

      {
        path: '/reward-manage/:lotteryId',
        name: 'RewardManage',
        hidden: true,
        component: () => import('@/views/Lottery/RewardManage'),
        meta: {
          title: '獎項管理',
          action: 'admin.lotteryAward.page',
        },
      },
      {
        path: '/lottery-create',
        name: 'LotteryCreate',
        hidden: true,
        component: () => import('@/views/Lottery/LotteryCreate'),
        meta: {
          title: '新增抽獎獎項',
          action: 'admin.lotteryAward.create',
        },
      },
      {
        path: '/lottery-history',
        name: 'LotteryHistory',
        component: () => import('@/views/Lottery/LotteryHistory'),
        meta: {
          title: '抽獎紀錄',
          action: 'admin.memberGameRecord.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBERGAME_RECORD_REPORT',
        },
      },
      // {
      //   path: '/coupon-setting',
      //   name: 'CouponSetting',
      //   component: () => import('@/views/Lottery/CouponSetting'),
      //   meta: {
      //     title: '使用券設定',
      //     action: 'admin.gameModule.*',
      //   },
      // },
      // {
      //   path: '/coupon-create/:id?',
      //   name: 'CouponCreate',
      //   hidden: true,
      //   component: () => import('@/views/Lottery/CouponCreate'),
      //   meta: {
      //     title: '使用券編輯',
      //     action: 'admin.gameModule.*',
      //   },
      // },
    ],
  },
]
