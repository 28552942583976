import Layout from '@/layout'

export const externalTransactionRoutes = [
  {
    path: '/external-transaction',
    component: Layout,
    name: 'externalTransaction',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '第三方交易模組',
    },
    children: [
      {
        path: 'external-transaction-activity',
        name: 'externalTransactionActivity',
        component: () => import('@/views/ExternalTransaction/ExternalTransactionActivity/ExternalTransactionActivity.vue'),
        meta: {
          title: '回饋活動設定',
          action: 'admin.memberExternalTransaction.page',
        },
      },
      {
        path: 'external-transaction-activity/:id?',
        name: 'ExternalTransactionActivityEdit',
        component: () => import('@/views/ExternalTransaction/ExternalTransactionActivityEdit/ExternalTransactionActivityEdit.vue'),
        hidden: true,
        meta: {
          action: 'admin.memberExternalTransaction.page',
        },
      },
    ],
  },
]
