<template>
  <div class="app-main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AppMain',
}
</script>

<style lang="scss" scoped>
.app-main {
    // height: 100%;
    // width: 100%;
    overflow: auto;
}
</style>
