
import { publicApi, admin } from '@/api/instance'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { checkUserFeature } from '@/store/modules/permission'

const baseURL = 'https://apptracker.tk'

const makeFormData = (payload) => {
  const formdata = new FormData()
  const globalData = {
    app_id: '110_思言科技',
    time: +new Date(),
    ip: '127.0.0.1', // FIXME IP 之後要動態帶入
  }

  Object.keys(globalData).forEach(key => {
    formdata.append(key, globalData[key])
  })

  Object.keys(payload).forEach(key => {
    if (formdata.has(key)) {
      formdata.set(key, payload[key])
      return
    }
    formdata.append(key, payload[key])
  })

  return formdata
}

export const state = () => {
  return {
    // 追蹤用資料
    member: {},
    orderItems: {},
    paymentItems: [],
    discountStack: [],

    // 模組用資料
    currentOpenEvent: {},
    eventHistory: {},
    usageRecord: {},
  }
}
export const mutations = {
  SET_SALES_RECORD (state, {
    member,
    orderStack,
    paymentStack,
    discountStack,
  }) {
    state.member = member
    state.orderItems = orderStack
    state.paymentItems = paymentStack
    state.discountStack = discountStack
  },
  REMOVE_SALES_RECORD (state) {
    state.member = {}
    state.orderItems = {}
    state.paymentItems = []
    state.discountStack = []
  },
  SET_CURRENT_OPEN_EVENT (state, { shopId, event }) {
    state.currentOpenEvent = Object.assign(
      { ...state.currentOpenEvent },
      { [shopId]: event },
    )
  },
  SET_EVENT_HISTORY (state, { shopId, resData }) {
    state.eventHistory = Object.assign(
      { ...state.eventHistory },
      { [shopId]: resData },
    )
  },
  SET_USAGE_RECORD (state, { shopId, resData }) {
    state.usageRecord = Object.assign(
      { ...state.usageRecord },
      { [shopId]: resData },
    )
  },
}

export const getters = {
  useAllianceActivity (state, getters, rootState, rootGetters) {
    return checkUserFeature(rootGetters.userPlanFeature, rootGetters.userFeatures, 'admin.allianceActivity.*')
  },
  currentEvent (state, getters) {
    const { shopId } = getters
    const shopCurrentEvent = state.currentOpenEvent[shopId]
    return (shopCurrentEvent && getters.formatEvent(shopCurrentEvent)) || {}
  },
  eventHistory (state, getters) {
    const { shopId } = getters
    return (state.eventHistory[shopId] || []).map(event => getters.formatEvent(event))
  },
  formatEvent () {
    return (event) => {
      let {
        id,
        title,
        description,
        image,
        category,
        startDate,
        endDate,
        redeemEndDate,
        redeemStartDate,
        type,
        status,
        count,
        useCount,
        redeemCount,
        linkUrl,
        cashAmount,
        percentAmount, // FIXME 轉換折數格式
      } = event
      startDate = dayjs(startDate).format('YYYY-MM-DD')
      endDate = dayjs(endDate).format('YYYY-MM-DD')
      redeemStartDate = dayjs(redeemStartDate).format('YYYY-MM-DD')
      redeemEndDate = dayjs(redeemEndDate).format('YYYY-MM-DD')
      const discountType_ = { percent: '百分比', cash: '金額' }[type]
      const eventDuration_ = `${startDate} - ${endDate}`
      const redeemDuration_ = `${redeemStartDate} - ${redeemEndDate}`
      return {
        id,
        eventQuota: count,
        usageAmount: useCount,
        issuanceAmount: redeemCount,
        startDate,
        endDate,
        redeemEndDate,
        redeemStartDate,
        eventDuration: [startDate, endDate],
        redeemDuration: [redeemStartDate, redeemEndDate],
        eventDuration_,
        redeemDuration_,
        status,
        type,
        shopCategory: category.id,
        shopCategoryName: category.name,
        img: { imageURL: image, filename: image.split('/').pop() },
        name: title,
        description,
        discountAmout: cashAmount || percentAmount,
        discountType: type,
        discountType_,
        shopUrl: linkUrl || '',
      }
    }
  },
  usageRecord (state, getters) {
    const { shopId } = getters

    return state.usageRecord[shopId] || []
  },
  shopId (state, getters, rootState, rootGetters) {
    return rootGetters.shop
  },
  shopName (state, getters, rootState, rootGetters) {
    return rootGetters.shopName
  },
  serviceItems (state) {
    const { orderItems } = state
    return Object.keys(orderItems).reduce((items, unitName) => {
      return items.concat(orderItems[unitName])
    }, [])
  },
  serviceItem (state, getters) {
    return getters.serviceItems.map(item => item.product.name)
  },
  serviceStaff (state, getters) {
    return getters.serviceItems.map(item => item.unit.name || '無')
  },
  serviceAmount (state, getters) {
    return getters.serviceItems.map(item => item.product.count)
  },
  servicePrice (state, getters) {
    return getters.serviceItems.map(item => item.product.defaultPrice)
  },
  totalPrice (state, getters) {
    return getters.serviceItems.reduce((total, item) => {
      return total + item.product.count * item.product.defaultPrice
    }, 0)
  },
  paymentTypes (state) {
    return state.paymentItems.map(item => item.type)
  },
  marketingLeagueDiscount (state) {
    return state.discountStack.find(item => item.isAllianceActivity)
  },
  hasUseMarketingLeagueDiscount (state, getters) {
    return !isEmpty(getters.marketingLeagueDiscount)
  },
  userPhone (state) {
    const user = state.member.UserInfo
    return user && user.phone
  },
  userGender (state) {
    const user = state.member.UserInfo
    const genderMap = {
      male: '男',
      female: '女',
    }
    return genderMap[user && user.gender] || ''
  },
  userAge (state) {
    const user = state.member.UserInfo
    const age = dayjs(user && user.birthday)
    return age.isValid() ? dayjs().year() - age.year() : ''
  },
  userAddress (state) {
    const user = state.member.UserInfo
    return user && user.address
  },

}

export const actions = {
  // 建立銷售紀錄時 追蹤資料
  async POST_TRACKING_DATA ({ getters, commit, dispatch }, payload) {
    if (!getters.useAllianceActivity) return
    commit('SET_SALES_RECORD', payload)
    dispatch('POST_SALES_RECORD')
    if (getters.hasUseMarketingLeagueDiscount) {
      dispatch('POST_DISCOUNT_USAGE_RECORD')
    }
    commit('REMOVE_SALES_RECORD')
  },
  // 思言回饋資訊
  POST_SALES_RECORD ({ getters }) {
    const {
      shopName,
      serviceItem,
      serviceStaff,
      serviceAmount,
      servicePrice,
      totalPrice,
      paymentTypes,
      userPhone,
      userAge,
      userGender,
    } = getters
    return publicApi({
      method: 'POST',
      baseURL,
      headers: { 'Content-Type': 'multipart/form-data' },
      url: '/app_restful/public/index.php/api/customers/add/31',
      data: makeFormData({
        mode: 31,
        device_id: userPhone,
        view: shopName,
        service_item: serviceItem.join('///'),
        staff: serviceStaff.join('///'),
        amount: serviceAmount.join('///'),
        price: servicePrice.join('///'),
        total_price: totalPrice,
        payment: paymentTypes.join('///'),
        time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        age: userAge,
        gender: userGender,
      }),
    })
  },
  // 商品消費資訊
  POST_DISCOUNT_USAGE_RECORD ({ getters }) {
    const {
      shopName,
      marketingLeagueDiscount,
      userPhone,
      userAddress,
    } = getters
    return publicApi({
      method: 'POST',
      baseURL,
      headers: { 'Content-Type': 'multipart/form-data' },
      url: '/app_restful/public/index.php/api/customers/add/9',
      data: makeFormData({
        mode: 9,
        store_name: marketingLeagueDiscount.name,
        product_name: marketingLeagueDiscount.discription,
        action: marketingLeagueDiscount.type,
        price: marketingLeagueDiscount.cashAmount,
        address: userAddress,
        device_id: userPhone,
        view: shopName,
        note1: marketingLeagueDiscount.issuanceAmount,
      }),
    })
  },
  // findCategory 查詢類別
  GET_SHOP_AVAILABLE_CATEGORY ({ getters }) {
    const { shopId } = getters
    return admin({
      url: `/${shopId}/allianceActivity/category`,
    })
      .then(res => res.data)
      .catch(() => [])
  },
  // createPreferentialPlan 建立活動
  CREATE_PREFERENTIAL_PLAN ({ getters }, {
    img,
    name,
    description,
    eventDuration,
    redeemDuration,
    discountType,
    discountAmout,
    eventQuota,
    shopCategory,
    shopUrl,

  }) {
    const { shopId } = getters
    const [startDate, endDate] = eventDuration
    const [redeemStartDate, redeemEndDate] = redeemDuration

    return admin({
      method: 'POST',
      url: `/${shopId}/allianceActivity/preferentialPlan`,
      data: {
        title: name,
        description: description,
        imageId: img.id,
        linkUrl: shopUrl,
        type: discountType,
        cashAmount: discountType === 'cash' ? discountAmout : 0,
        percentAmount: discountType === 'percent' ? discountAmout : 0,
        count: eventQuota,
        startDate: startDate,
        endDate: endDate,
        redeemStartDate: redeemStartDate,
        redeemEndDate: redeemEndDate,
        category: shopCategory,
      },
    }).then(({ data }) => ({ OK: true, data }))
      .catch((error) => ({ OK: false, error }))
  },
  async GET_CURRENT_PREFERENTIAL_PLAN ({ dispatch, commit, getters }, payload) {
    const { shopId } = getters
    const res = await dispatch('GET_PREFERENTIAL_PLAN', payload)
    const [currentEvent] = res.resData
    commit('SET_CURRENT_OPEN_EVENT', { shopId, event: currentEvent })
    return res
  },
  async GET_PAGE_PREFERENTIAL_PLAN ({ dispatch, commit, getters }, payload) {
    const { shopId } = getters
    const res = await dispatch('GET_PREFERENTIAL_PLAN', payload)
    commit('SET_EVENT_HISTORY', { shopId, resData: res.resData })
    return res
  },
  // findPreferentialPlan 查詢活動
  async GET_PREFERENTIAL_PLAN ({ getters }, { page, limit, name, status }) {
    const { shopId } = getters
    const title = name || undefined
    const { count, list } = await admin({
      url: `/${shopId}/allianceActivity/preferentialPlan`,
      params: {
        limit,
        start: page && (page - 1) * limit,
        title,
        status,
      },
    })
      .then(res => res.data)
      .catch(() => ({ count: 0, list: [] }))
    return { count, resData: list }
  },
  // updatePreferentialPlan 更新活動
  UPDATE_PREFERENTIAL_PLAN ({ getters }, {
    id,
    img,
    name,
    description,
    eventQuota,
    redeemDuration,
    shopCategory,
    shopUrl,
  }) {
    const { shopId } = getters
    const [redeemStartDate, redeemEndDate] = redeemDuration

    return admin({
      method: 'PUT',
      url: `/${shopId}/allianceActivity/preferentialPlan/${id}`,
      data: {
        title: name,
        description: description,
        imageId: img.id,
        linkUrl: shopUrl,
        count: eventQuota,
        redeemStartDate: redeemStartDate,
        redeemEndDate: redeemEndDate,
        category: shopCategory,
      },
    })
      .then(async ({ data }) => {
        return { OK: true, data }
      })
      .catch((error) => ({ OK: false, error }))
  },
  // closePreferentialPlan 關閉活動
  CLOSE_PREFERENTIAL_PLAN ({ getters, dispatch }, {
    id,
  }) {
    const { shopId } = getters
    return admin({
      method: 'PUT',
      url: `/${shopId}/allianceActivity/preferentialPlan/${id}/close`,
    })
      .then(() => {
        return dispatch('GET_PREFERENTIAL_PLAN')
      })
      .catch(() => {})
  },
  // findVoucherHistory 查詢聯盟行銷使用列表
  async FIND_USAGE_RECORD ({ getters, commit }, { page, limit, phone }) {
    phone = phone || undefined
    const { shopId } = getters
    const res = await admin({
      url: `${shopId}/allianceActivity/voucherHistory`,
      params: {
        limit,
        start: (page - 1) * limit,
        phone,
      },
    })
      .then(res => res.data)
      .catch(() => ({ count: 0, list: [] }))
    commit('SET_USAGE_RECORD', { shopId, resData: res.list })
    return res
  },
  // findVoucherHistory 查詢聯盟行銷使用列表
  async GET_USER_MARKETING_LEAGUE_DISCOUNT ({ getters }, { phone }) {
    const { shopId, useAllianceActivity } = getters
    if (!useAllianceActivity) return []
    return await admin({
      url: `/${shopId}/allianceActivity/voucherHistory`,
      params: {
        phone: phone,
      },
    })
    /**
     * 一般優惠的格式
     * {
     * "id":"357faaa7-fd98-4c51-bc7a-c18dd00a3027",
     * "name":"並存測試",
     * "type":"percent",
     * "cashAmount":0,
     * "percentAmount":0.01,
     * "order":1,
     * "isRemove":false,
     * "createdAt":"2021-07-16T13:16:42.871Z",
     * "updatedAt":"2021-07-16T13:19:01.616Z",
     * "ShopId":"d60c0a23-05b4-4bca-a066-382485b0ad15"
     * }
     */
      .then(res => res.data.list
        .filter(item => item.status === 'issued') //  過濾狀態為可使用的
        .map(item => ({
          ...item.preferentialPlan,
          id: item.id,
          preferentialPlanId: item.preferentialPlan.id,
          issuanceAmount: item.preferentialPlan.count,
        }))
        .map(({
          id,
          title,
          type,
          cashAmount,
          percentAmount,
          preferentialPlanId,
          issuanceAmount,
        }) => ({
          id,
          preferentialPlanId,
          type,
          cashAmount,
          percentAmount,
          name: title,
          issuanceAmount,
          order: -1,
          isAllianceActivity: true,
        })) // 轉換成 SalesRecordCreate Page 的資料格式
        .reduce((list, discount) => {
          const sameEvent = list.find(item => item.preferentialPlanId === discount.preferentialPlanId)
          if (!sameEvent) {
            list.push(discount)
          }
          return list
        }, []), // 過濾重複的活動
      )
      .catch(() => [])
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
