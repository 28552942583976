<template>
  <div class="title-with-hint">
    <p class="title">{{ title }}</p>
    <p class="hint">{{ hint }}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleWithHint',
  props: ['title', 'hint'],
}
</script>

<style lang="postcss" scoped>
.title {
  @apply text-[24px] font-medium;
}

.hint {
  @apply text-sub text-gray-80 font-medium mt-[15px];
}
</style>
