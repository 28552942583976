import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/mTag`
}

//= > 創建標籤
export const CreateTags = async ({ shopId, name }) => {
  return await admin2({
    method: 'post',
    url: `${baseUrl(shopId)}`,
    data: {
      name,
    },
  })
}

//= > 取得標籤
export const GetTags = async ({ shopId, start, limit, isSystem, name }) => {
  const res = await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      isSystem,
      name,
    },
  })

  return res
}

//= > 取得標籤總數
export const GetTagsCount = async ({ shopId, name, isSystem }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
      isSystem,
    },
  })

  return res.data.count
}

//= > 取得特定標籤
export const FindTags = async ({ shopId, id }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新標籤
export const UpdateTags = async ({ shopId, id, name }) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
    },
  })

  return res.data
}

//= > 刪除標籤
export const DeleteTags = async ({ shopId, id }) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 標籤受眾數
export const EstimateTags = async ({ shopId, id }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/estimate`,
  })

  return res.data
}

//= > 自動標籤
export const AutoTags = async ({ shopId, systemName, name, members }) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/systemUser`,
    data: {
      systemName,
      name,
      members,
    },
  })

  return res.data
}
