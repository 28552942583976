export const useFetchAll = (fetchAPI) => {
  const fetchAll = async (payload) => {
    let start = 0
    const data = []
    const _limit = 100

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        return [null, err]
      }
      start += _limit
      data.push(...res)
      if (res.length < _limit) {
        break
      }
    }
    return [data, null]
  }
  return {
    fetchAll,
  }
}
