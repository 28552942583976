import Layout from '@/layout'

export const classTicketRoutes = [
  // 堂票模組
  {
    path: '/class-ticket',
    component: Layout,
    name: 'ClassTicket',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '堂票模組',
    },
    children: [
      {
        path: 'settings',
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '堂票管理',
        },
        children: [
          {
            path: 'class-ticket-setting',
            name: 'ClassTicketSetting',
            component: () => import('@/views/ClassTicket/ClassTicketSetting'),
            meta: {
              title: '堂票設定',
              action: 'admin.classTicket.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_CLASSTICKET_LIST',
            },
          },
          {
            path: '/create-class-ticket/:ticketId?',
            name: 'CreateClassTicket',
            hidden: true,
            component: () => import('@/views/ClassTicket/CreateClassTicket/CreateClassTicket'),
            meta: {
              title: '新增堂票',
              action: 'admin.classTicket.page',
            },
          },
        ],
      },

      {
        path: 'class-ticket-record',
        name: 'ClassTicketRecord',
        component: () => import('@/views/ClassTicket/ClassTicketRecord'),
        meta: {
          title: '堂票紀錄列表',
          action: 'admin.classTicketRecord.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_CLASSTICKET_RECORD_LIST',
        },
      },
    ],
  },
]
