<template>
  <footer class="ohbot-footer">
    <OhbotLogo />
    <p>思言科技股份有限公司 Sien Tech Ltd.</p>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import OhbotLogo from '@/components/OhbotLogo.vue'

export default defineComponent({
  name: 'OhbotFooter',
  components: { OhbotLogo },
})
</script>

<style lang="postcss" scoped>
.ohbot-footer {
    @apply absolute bottom-[20px] left-0 right-0 m-auto;
    @apply flex flex-col justify-center items-center justify-center;
}
</style>
