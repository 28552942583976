import Tag from '@/components/Tag/Tag.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import BaseTable from '@/components/BaseTable.vue'
// Button
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import TableSettingIconButton from '@/components/Button/TableSettingIconButton.vue'
import AddButton from '@/components/Button/AddButton.vue'
// Title
import PageTitle from '@/components/Title/PageTitle.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import TitleWithHint from '@/components/Title/TitleWithHint.vue'
import DialogTitleWithHint from '@/components/Title/DialogTitleWithHint.vue'
// Footer
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
// Dialog
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
// Block
import EmptyBlock from '@/components/EmptyBlock.vue'
// Container
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import BasicTabs from '@/components/Tabs/BasicTabs.vue'

import MaterialIcon from '@/components/MaterialIcon.vue'
import SVGIconVue from '@/components/SVGIcon.vue'

export default {
  install (Vue) {
    Vue.component('MaterialIcon', MaterialIcon)
    Vue.component('BaseTable', BaseTable)
    Vue.component('Tag', Tag)
    Vue.component('Pagination', Pagination)
    // Button
    Vue.component('AddButton', AddButton)
    Vue.component('TableEditBtnGroup', TableEditBtnGroup)
    Vue.component('TableSettingIconButton', TableSettingIconButton)
    // Title
    Vue.component('PageTitle', PageTitle)
    Vue.component('SectionTitle', SectionTitle)
    Vue.component('TitleWithHint', TitleWithHint)
    Vue.component('DialogTitleWithHint', DialogTitleWithHint)
    // Footer
    Vue.component('PageFixedFooter', PageFixedFooter)
    // Dialog
    Vue.component('DeleteDialog', DeleteDialog)
    // Block
    Vue.component('EmptyBlock', EmptyBlock)
    // Container
    Vue.component('FiltersContainer', FiltersContainer)

    Vue.component('BasicTabs', BasicTabs)
    Vue.component('SVGIcon', SVGIconVue)
  },
}
