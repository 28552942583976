import Layout from '@/layout'

// 行銷模組
export default {
  path: '/marketing',
  component: Layout,
  name: 'Marketing',
  alwaysShow: true,
  isGroup: true,
  meta: {
    title: '行銷模組',
  },
  children: [
    {
      path: 'tags-setting',
      component: {
        render (c) {
          return c('router-view')
        },
      },
      meta: {
        title: '行銷設定',
      },
      children: [
        {
          path: '',
          component: () => import('@/views/Marketing/TagsSetting'),
          name: 'TagsSetting',
          meta: {
            title: '標籤設定',
            action: 'admin.mTag.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_TAG_LIST',
          },
        },
        {
          path: 'tags-advanced-search',
          component: () => import('@/views/Marketing/TagsAdvancedSearch'),
          name: 'TagsAdvancedSearch',
          hidden: true,
          meta: {
            title: '標籤設定',
            action: 'admin.mTag.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_TAG_LIST',
          },
        },
      ],
    },

    {
      path: 'settings',
      component: {
        render (c) {
          return c('router-view')
        },
      },

      name: 'MarketingSetting',
      meta: {
        title: '分群推播管理',
      },
      children: [
        {
          path: 'push-message-setting',
          component: () =>
            import('@/views/Marketing/PushMessageManage/PushMessageSetting'),
          name: 'PushMessageSetting',
          meta: {
            title: '分群推播列表',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_LIST',
          },
        },
        {
          path: 'push-message',
          component: () => import('@/views/Marketing/PushMessage'),
          name: 'PushMessage',
          hidden: true,
          meta: {
            title: '分群推播',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_CREATE',
          },
        },
        {
          path: 'push-coupon',
          component: () => import('@/views/Marketing/PushCoupon'),
          name: 'PushCoupon',
          hidden: true,
          meta: {
            title: '分群推播',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_CREATE',
          },
        },
      ],
    },
    {
      path: '/event-marketing-list',
      name: 'EventMarketingList',
      component: () =>
        import('@/views/Marketing/EventMarkingList/EventMarkingList.vue'),
      meta: {
        title: '事件行銷列表',
        action: 'admin.eventTemplateConfig.page',
      },
    },
    {
      path: '/event-marketing/:id?',
      name: 'EventMarketingEdit',
      hidden: true,
      component: () =>
        import('@/views/Marketing/EventMarketingEdit/EventMarketingEdit.vue'),
      meta: {
        title: '編輯事件行銷',
        action: 'admin.eventTemplateConfig.create',
      },
    },
    {
      path: '/tracking-link-management',
      name: 'TrackingLinkManagement',
      component: () => import('@/views/Marketing/TrackingLinkManagement'),
      meta: {
        title: '追蹤連結管理',
        action: 'admin.trackingLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/editTrackingLink/:id?',
      name: 'EditTrackingLink',
      component: () => import('@/views/Marketing/EditTrackingLink'),
      hidden: true,
      meta: {
        title: '編輯連結管理',
        action: 'admin.trackingLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/member-invite-setting',
      name: 'MemberInviteSetting',
      component: () => import('@/views/Marketing/MemberInviteSetting/MemberInviteSetting.vue'),
      meta: {
        title: '推薦會員活動設定',
        action: 'admin.referralActivity.page',
      },
    },
    {
      path: '/member-invite-edit',
      name: 'MemberInviteEdit',
      hidden: true,
      component: () => import('@/views/Marketing/MemberInviteSetting/components/MemberInviteEdit.vue'),
      meta: {
        title: '推薦會員活動邊編輯',
        action: 'admin.referralActivity.page',
      },
    },
    {
      path: '/member-invite-report',
      name: 'MemberInviteReport',
      component: () => import('@/views/Marketing/MemberInviteReport/MemberInviteReport.vue'),
      meta: {
        title: '推薦會員報表',
        action: 'admin.referralActivity.page',
      },
    },
  ],
}
