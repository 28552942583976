import { FindPageSetting, UpdatePageSetting } from '@/api/ecommerce/pageSetting'
import { GetActivity } from '@/api/ecommerce/activity'
import { GetProductCategory, GetProductCategoryCount } from '@/api/ecommerce/category'
import formUtils from '@/utils/form'
import { getAllDataFromApi } from '@/utils/helper'

const state = {
  homePageConfig: [],
  activitys: [],
  categorys: [],
  bannerForm: null,
  productListForm: null,
  mediaReportForm: null,
  recommandForm: null,
  textStoryForm: null,
  marketingForm: null,
  youtubeLinkForm: null,
}

const mutations = {
  SET_HOME_PAGE_CONFIG: (state, config) => {
    state.homePageConfig = config
  },
  SET_ACTIVITYS: (state, data) => {
    state.activitys = data
  },
  SET_CATEGORYS: (state, data) => {
    state.categorys = data
  },
  SET_FORM_REF: (state, { form, ref }) => {
    state[form] = ref
  },
  RESET_HOME_PAGE_STORE: (state) => {
    state.homePageConfig = []
    state.activitys = []
    state.categorys = []
    state.bannerForm = null
    state.productListForm = null
    state.mediaReportForm = null
    state.recommandForm = null
    state.textStoryForm = null
    state.marketingForm = null
    state.youtubeLinkForm = null
  },
}

const actions = {

  async FindHomePageConfig ({ commit, rootGetters }) {
    const [res, error] = await FindPageSetting({ shopId: rootGetters.shop, key: 'home' })
    if (error) return [null, error]
    if (res) {
      commit('SET_HOME_PAGE_CONFIG', res.content?.areas)
    }
    return [res, null]
  },
  async GetActivity ({ commit, rootGetters }) {
    const [res, error] = await GetActivity({
      shopId: rootGetters.shop,
      enable: true,
    })
    if (error) return [null, error]
    commit('SET_ACTIVITYS', res)
    return [res, null]
  },
  async GetProductCategory ({ commit, rootGetters }) {
    const [max, err] = await GetProductCategoryCount({ shopId: rootGetters.shop })
    if (err) return [null, err]
    const config = {
      start: 0,
      limit: 100,
      shopId: rootGetters.shop,
    }
    const [res, error] = await getAllDataFromApi(max.count, GetProductCategory, config, true)
    if (error) return [null, error]
    commit('SET_CATEGORYS', res)
    return [res, null]
  },

  async checkAllForm ({ state }, igonreList = []) {
    const handleCheck = async (key) => {
      if (igonreList.includes(key)) return true
      if (!await formUtils.checkForm(state[key])) {
        return false
      }
      return true
    }
    if (!await handleCheck('bannerForm')) return false
    if (!await handleCheck('productListForm')) return false
    if (!await handleCheck('recommandForm')) return false
    if (!await handleCheck('mediaReportForm')) return false
    if (!await handleCheck('textStoryForm')) return false
    if (!await handleCheck('marketingForm')) return false
    if (!await handleCheck('youtubeLinkForm')) return false
    // if(!await formUtils.checkForm(state.bannerForm)) return false
    // if(!await formUtils.checkForm(state.productListForm)) return false
    // if(!await formUtils.checkForm(state.recommandForm)) return false
    // if(!await formUtils.checkForm(state.mediaReportForm)) return false
    // if(!await formUtils.checkForm(state.textStoryForm)) return false
    // if(!await formUtils.checkForm(state.marketingForm)) return false
    // if(!await formUtils.checkForm(state.youtubeLinkForm)) return false
    return true
  },

  async updateHomePageSetting ({ rootGetters }, { key, content }) {
    const [res, err] = await UpdatePageSetting({
      shopId: rootGetters.shop,
      key,
      content,
    })
    if (err) {
      return [null, err]
    }
    return [res, null]
  },

}

export default {
  state,
  mutations,
  actions,
}
