import configuration from '@/configuration'

export const getAppVersion = () => {
  let version
  const appVersion = configuration('appVersion')
  const envVersion = process.env.VUE_APP_TAG_VERSION
  if (appVersion) {
    version = appVersion.split('-')[0]
    return version
  }
  if (envVersion) {
    version = envVersion.split('-')[0]
    return version
  }
  // return process.env.VUE_APP_VERSION.split('-')[0]
  return '0.0.0'
}
