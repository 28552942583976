import { admin2 } from '@/api/instance'

export const FindShopRebate = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashback`,
  })
}

export const RegisterShopRebate = async ({
  shopId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCashback/register`,
  })
}

// 期限
export const FindShopRebateYearExpiration = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashback/yearExpiration`,
  })
}

export const UpdateShopRebateYearExpiration = async ({
  shopId,
  daysUntilExpiration,
  daysDiffExpirationNotify,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopCashback/yearExpiration`,
    data: {
      daysUntilExpiration,
      daysDiffExpirationNotify,
    },
  })
}

// 銷售
export const FindShopRebateSalesCashback = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/salesCashback`,
  })
}

export const UpdateShopRebateSalesCashback = async ({
  shopId,
  enabled,
  addRate,
  ignoreAddSalesProducts,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/salesCashback`,
    data: {
      enabled,
      addRate,
      ignoreAddSalesProducts,
    },
  })
}

// 電商
export const FindShopRebateEcommerce = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/ecommerceCashback`,
  })
}

export const UpdateShopRebateEcommerce = async ({
  shopId,
  enabled,
  addRate,
  useIgnoreAllActivity,
  ignoreAddEcommerceProducts,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/ecommerceCashback`,
    data: {
      enabled,
      addRate,
      useIgnoreAllActivity,
      ignoreAddEcommerceProducts,
    },
  })
}

// 會員頁回饋金
export const FindMemberRebateWallet = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/cashbackWallet`,
  })
}

export const FindMemberRebateWalletYearExpirations = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/cashbackWalletYearExpirations`,
  })
}

export const FindMemberRebateWalletRecord = async ({
  shopId,
  memberId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/cashbackWalletRecord`,
    params: {
      start,
      limit,
    },
  })
}
export const FindMemberRebateWalletRecordCount = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/cashbackWalletRecord/count`,
  })
}

export const AddMemberRebateWalletRecord = async ({
  shopId,
  memberId,
  addAmount,
  originMessage,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/addCashbackWalletRecord`,
    data: {
      addAmount,
      originMessage,
    },
  })
}

export const UseMemberRebateWalletRecord = async ({
  shopId,
  memberId,
  useAmount,
  originMessage,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/useCashbackWalletRecord`,
    data: {
      useAmount,
      originMessage,
    },
  })
}

// report
export const GetRebateRecord = async ({
  shopId,
  createStart,
  createEnd,
  start,
  limit,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashbackReport/records`,
    params: {
      createStart,
      createEnd,
      start,
      limit,
      origin,
      memberId,
    },
  })
}

export const GetRebateRecordCount = async ({
  shopId,
  createStart,
  createEnd,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashbackReport/records/count`,
    params: {
      createStart,
      createEnd,
      origin,
      memberId,
    },
  })
}

export const GetOriginTotalAmount = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashbackReport/originTotalAmount`,
    params: {
      createStart,
      createEnd,
    },
  })
}

export const GetOriginTotalAmountByBranch = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCashbackReport/originTotalAmountByBranch`,
    params: {
      createStart,
      createEnd,
    },
  })
}
