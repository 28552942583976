import Layout from '@/layout'

export const otherRoutes = [
  {
    path: '/other',
    component: Layout,
    name: 'Other',
    alwaysShow: true,
    isGroup: true,
    hidden: true,
    children: [
      {
        path: '/pos-payment-recognize',
        name: 'PosPaymentRecognize',
        hidden: true,
        component: () => import('@/views/Other/PosPaymentRecognize.vue'),
        meta: {
          title: '第三方POS支付方式認列',
        //   action: 'admin.shopPoint.page',
        },
      }],
  },
]
