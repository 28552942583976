import {
  GetUserInfoSetting,
} from '@/api/org/org'

const state = {
  org: {},
  userRegisterConfig: null,
}

const mutations = {
  SET_ORG: (state, org) => {
    state.org = org
  },
}

const actions = {
  async getUserInfoSetting ({ state }, orgId) {
    const [res, err] = await GetUserInfoSetting({
      orgId,
    })

    if (err) {
      if (err.status === 404) return
      return err
    }
    state.userRegisterConfig = res.fields
    return null
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
