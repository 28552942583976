import { bob, admin2 } from '@/api/instance'

export const pageActionRecord = async ({ data }) => {
  return await bob({
    method: 'POST',
    url: '/pageAction',
    data: {
      data,
    },
  })
}

export const GetPageActionRecord = async ({
  shopId,
  start,
  end,
  splitType,
  key,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopAnalysis/pageAction`,
    params: {
      start,
      end,
      splitType,
      key,
    },
  })
}

export const GetUserActionLog = async ({
  shopId,
  start,
  end,
  splitType,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopAnalysis/userLog`,
    params: {
      start,
      end,
      splitType,
      action: 'ADMIN_LOGIN_SUCCESS',
    },
  })
}
