<template>
  <div class="no-network">
    <MaterialIcon :size="40">wifi_off</MaterialIcon>
    <p>抱歉，當前無法偵測到網路連線</p>
    <p>請檢查您的網路連線</p>

    <OhbotFooter />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MaterialIcon from '@/components/MaterialIcon.vue'
import OhbotFooter from '@/components/OhbotFoooter.vue'

export default defineComponent({
  name: 'NoNetwork',
  components: { MaterialIcon, OhbotFooter },
  setup (props) {
  },
})
</script>

<style lang="postcss" scoped>
.no-network {
    @apply h-full w-full relative;
    @apply p-[20px];
    @apply flex flex-col items-center justify-center;
}

.company {
    @apply absolute bottom-[20px] left-0 right-0 m-auto;
    @apply flex flex-col justify-center items-center justify-center;
}
</style>
