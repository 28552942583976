<template>
  <span
    class="material-icons"
    :class="{'cursor-pointer': pointer}"
    :style="`font-size: ${size}px; width: ${size}px; color: ${color} `"
  >
    <slot />
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MaterialIcon',
  props: {
    color: { type: String, default: 'var(--base)' },
    size: [String, Number],
    pointer: Boolean,
  },
})
</script>
