var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-tag',{class:{
    info: _vm.type === 'info',
    warning: _vm.type === 'warning',
    danger: _vm.type === 'danger',
    action: _vm.type === 'action',
    success: _vm.type === 'success',
  },attrs:{"type":_vm.type,"disable-transitions":"","closable":_vm.closable,"size":_vm.size},on:{"close":function($event){return _vm.$emit('close')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }