import Layout from '@/layout'

export const walletRoutes = [
  // -- 儲值金模組
  {
    path: '/wallet',
    component: Layout,
    name: 'Wallet',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '儲值金模組',
    },
    children: [
      {
        path: '/wallet-report',
        name: 'WalletReport',
        component: () => import('@/views/Wallet/WalletReport.vue'),
        meta: {
          title: '儲值金報表',
          action: 'admin.walletRecord.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_WALLET_REPORT',
        },
      },
    ],
  },
]
