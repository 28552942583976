import Layout from '@/layout'

export const companyContactMenuRoutes = [
  {
    path: '/company-module',
    component: Layout,
    name: 'CompanyModule',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '企業模組',
      action: 'admin.shopCompanyDirectory.page',
    },
    children: [
      {
        path: '/company-contact-menu',
        name: 'CompanyContactMenu',
        component: () => import('@/views/Company/CompanyContactMenu/CompanyContactMenu'),
        meta: {
          title: '企業通訊錄',
          action: 'admin.shopCompanyDirectory.page',
        },
      },
      {
        path: '/contact-menu-import-result/:taskId',
        name: 'ContactMenuImportResult',
        hidden: true,
        component: () => import('@/views/Company/CompanyContactMenu/components/ImportResult.vue'),
        meta: {
          title: '匯入結果處理',
          action: 'admin.shopCompanyDirectory.page',
        },
      },
      {
        path: '/company-detail',
        name: 'CompanyDetail',
        hidden: true,
        component: () => import('@/views/Company/CompanyDetail/CompanyDetail.vue'),
        meta: {
          title: '企業資訊',
          action: 'admin.shopCompanyDirectory.page',
        },
      },
    ],
  },
]
