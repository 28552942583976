import { admin2 } from '@/api/instance'

export const RegisterShopRebate = async ({
  shopId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopPoint/register`,
  })
}

export const FindShopPoint = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPoint`,
  })
}

export const UpdateShopPoint = async ({
  shopId,
  name,
  unitName,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopPoint`,
    data: {
      name,
      unitName,
    },
  })
}

export const GetShopPointYearExpiration = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPoint/yearExpiration`,
  })
}

export const UpdateShopPointYearExpiration = async ({
  shopId,
  daysUntilExpiration,
  daysDiffExpirationNotify,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopPoint/yearExpiration`,
    data: {
      daysUntilExpiration,
      daysDiffExpirationNotify,
    },
  })
}

export const FindMemberPointYearExpirations = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/pointWalletYearExpirations`,
  })
}

export const AddPointWalletRecord = async ({
  shopId,
  memberId,
  addAmount,
  originMessage,
  branchId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/addPointWalletRecord`,
    data: {
      addAmount,
      originMessage,
      branchId,
    },
  })
}

export const UsePointWalletRecord = async ({
  shopId,
  memberId,
  useAmount,
  originMessage,
  branchId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/usePointWalletRecord`,
    data: {
      useAmount,
      originMessage,
      branchId,
    },
  })
}

export const FindPointWalletRecord = async ({
  shopId,
  memberId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/member/${memberId}/pointWalletRecord`,
    params: {
      start,
      limit,
    },
  })
}

export const FindPointWalletRecordCount = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/member/${memberId}/pointWalletRecord/count`,
  })
}

export const GetOriginTotalAmount = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/originTotalAmount`,
    params: {
      createStart,
      createEnd,
    },
  })
}

export const GetOriginTotalAmountByBranch = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/originTotalAmountByBranch`,
    params: {
      createStart,
      createEnd,
    },
  })
}

// report
export const GetPointRecord = async ({
  shopId,
  createStart,
  createEnd,
  start,
  limit,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/records`,
    params: {
      createStart,
      createEnd,
      start,
      limit,
      origin,
      memberId,
    },
  })
}

export const GetPointRecordCount = async ({
  shopId,
  createStart,
  createEnd,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/records/count`,
    params: {
      createStart,
      createEnd,
      origin,
      memberId,
    },
  })
}
