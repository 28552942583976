/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios'
import store from '@/store'
import configuration from '@/configuration'

// --- handler ---
const handleSuccessRes = (response) => {
  return response
}
const handleErrorRes = (error) => {
  if (error.code === 'ECONNABORTED') {
    return Promise.reject('請求逾時')
  }
  if (!error.response) {
    return Promise.reject('系統錯誤')
  }
  const { data } = error.response
  const { type, msg, status } = data
  console.log(`err: ${type}, ${status}, ${msg}`) // for debug
  if (status === 503) return Promise.reject(503)
  if (status === 403) {
    // ---- 權限錯誤
    // return Promise.reject(`${type}: ${msg}` || error.message)
  }
  return Promise.reject(`${msg}` || error.message)
}

const SuccessRes = (response) => {
  return [response.data, null]
}
const ErrorRes = (error) => {
  if (error.code === 'ECONNABORTED') {
    return [null, '請求逾時']
  }

  if (!error.response) {
    return [null, '系統錯誤']
  }
  const { data } = error.response
  const { type, msg, status, errorCode } = data
  console.log(`err: ${type}, ${status}, ${msg}`) // for debug
  if (errorCode === 'COUPON_EXPAT_MUST_LESS_THAN_ENDAT') {
    return [null, { code: errorCode, msg: '使用券過期於活動時間' }]
  }
  if (status === 404) return [null, data]
  // if (status === 403) {}
  if (status === 503) return [null, 503]
  return [null, `${type}: ${msg}` || error.message]
}

// --- admin ---
export const admin = axios.create({
  baseURL: `${configuration('backendHost')}/api-admin`,
})

admin.interceptors.request.use(async (config) => {
  // 動態取得 vuex state 當前 token 設置為 Bearer Token
  config.headers.Authorization = `Bearer ${store.getters.token}`

  // const token = ls.getToken()
  // config.headers.Authorization =  `Bearer ${token}`

  return config
})
admin.interceptors.response.use(handleSuccessRes, handleErrorRes)

// --- admin 2 ---
export const admin2 = axios.create({
  baseURL: `${configuration('backendHost')}/api-admin`,
})

admin2.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${store.getters.token}`
  return config
})
admin2.interceptors.response.use(SuccessRes, ErrorRes)

// --- admin public ---
export const adminPublic = axios.create({
  baseURL: `${configuration('backendHost')}/api-admin`,
})

// --- ohbot ---
export const ohbot = axios.create({
  baseURL: `${configuration('backendHost')}/api-ohbot`,
})

// --- public ---
export const publicApi = axios.create({
  baseURL: `${configuration('backendHost')}/api`,
})

// --- bob ---
export const bob = axios.create({
  baseURL: `${configuration('bobAnalyticsHost')}/api-bob`,
})

bob.interceptors.response.use(SuccessRes, ErrorRes)
