import Layout from '@/layout/index.vue'

export const memberCenterRoutes = [
  {
    path: '/member-cenrter',
    component: Layout,
    alwaysShow: false,
    isGroup: false,
    hidden: true,
    name: 'MemberCenter',
    meta: {
      title: '會員中心',
    },
    children: [
      {
        path: 'color-theme-setting',
        name: 'MemberCenterColorThemeSetting',
        component: () => import('@/views/Parameters/MemberCenter/components/MemberCenterColorThemeSetting.vue'),
        meta: {
          title: '會員中心色系主題設定',
          action: 'admin.member.page',
        },
      },
      {
        path: 'color-theme-edit/:styleName?/:id?',
        name: 'MemberCenterColorThemeEdit',
        component: () => import('@/views/Parameters/MemberCenter/components/MemberCenterColorThemeEdit.vue'),
        meta: {
          title: '會員中心色系主題編輯',
          action: 'admin.member.page',
        },
      },
    ],
  },
]
