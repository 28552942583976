<template>
  <el-dialog
    :visible="show"
    @close="$emit('close')"
  >
    <div>
      <input
        id="wtf"
        v-model="search"
        autocomplete="off"
        @keydown.up.prevent="onKeyUp"
        @keydown.down.prevent="onKeyDown"
        @input="selectIndex = 0"
        @keydown.enter.prevent="onEnter"
      >

      <div class="options-list">
        <div
          v-for="(route, index) in matchs"
          :key="`${index}-${route.name}`"
          :class="{selected: index === selectIndex}"
        >
          {{ get(route.meta, 'title') || route.name }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { asyncRoutes, orgSettingsRoutes, constantRoutes } from '@/router'
import { get, map, flattenDeep } from 'lodash'

export default {
  name: 'Commander',
  data: () => ({
    show: true,
    search: '',
    selectIndex: 0,
    asyncRoutes,
  }),
  computed: {
    matchs () {
      const searchName = this.search.trim()
      if (searchName) {
        return this.routes.filter(i => {
          const title = get(i.meta, 'title')
          if (title) return title.toLowerCase().includes(searchName)
          if (i.name) return i.name.toLowerCase().includes(searchName)
          return false
        })
      }
      return this.routes
    },
    asyncRoutesChilds () {
      const mods = asyncRoutes()
      return flattenDeep(map(mods, 'children').filter(i => i))
    },
    routes () {
      return [
        ...this.asyncRoutesChilds,
        ...orgSettingsRoutes,
        ...constantRoutes,
      ]
    },
  },
  async mounted () {
    this.$nextTick(() => {
      const el = document.getElementById('wtf')
      el.focus()
    })
  },
  methods: {
    get,
    onKeyUp () {
      if (this.selectIndex > 0) this.selectIndex--
    },
    onKeyDown () {
      if (this.selectIndex < this.matchs.length - 1) this.selectIndex++
    },
    onEnter () {
      if(!this.search.trim()) return
      const route = this.matchs[this.selectIndex]
      this.$router.push({
        name: route.name
      })
      this.$emit('close')
    }
  },
}
</script>

<style lang="postcss" scoped>
.selected {
  @apply bg-primary-100 text-white;
}

.options-list {
  @apply h-[300px] overflow-y-auto;
}
</style>
