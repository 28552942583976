import { render, staticRenderFns } from "./OhbotFoooter.vue?vue&type=template&id=759d504f&scoped=true"
import script from "./OhbotFoooter.vue?vue&type=script&lang=js"
export * from "./OhbotFoooter.vue?vue&type=script&lang=js"
import style0 from "./OhbotFoooter.vue?vue&type=style&index=0&id=759d504f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "759d504f",
  null
  
)

export default component.exports