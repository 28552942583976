<template>
  <el-tag
    :type="type"
    :class="{
      info: type === 'info',
      warning: type === 'warning',
      danger: type === 'danger',
      action: type === 'action',
      success: type === 'success',
    }"
    disable-transitions
    :closable="closable"
    :size="size"
    @close="$emit('close')"
  >
    <slot />
  </el-tag>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    type: [String, Number],
    size: {
      type: String,
      default: 'medium',
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="postcss">
.info {
  @apply bg-info-light border border-info text-info;
}

.action {
  @apply bg-action-light border border-action text-action;
}

.warning {
  @apply bg-warning-light border border-warning text-warning;
}

.danger {
  @apply bg-danger-light border border-danger text-danger;
}

.success {
  @apply bg-success-light border border-success text-success;
}

::v-deep .el-tag__close {
  @apply text-gray-100;
}

::v-deep .el-tag__close:hover {
  @apply bg-primary-100 text-white;
}
</style>
