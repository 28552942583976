import store from './store'
import { find } from 'lodash'
import configuration from '@/configuration'
import { GetOrgsByCode } from './api/auth'

const changeCSS = (theme) => {
  const links = document.getElementsByTagName('link')
  const oldLink = find(links, { id: 'theme' })

  const newLink = document.createElement('link')
  newLink.setAttribute('rel', 'stylesheet')
  newLink.setAttribute('id', 'theme')
  newLink.setAttribute('href', import(`@/styles/themes/${theme}.css`))

  if (!oldLink) {
    document.head.appendChild(newLink)
    return
  }

  document
    .getElementsByTagName('head')
    .item(0)
    .replaceChild(newLink, oldLink)
}

export const setOrgConfig = async () => {
  let activeAppTheme = ''

  // -- 檢查 .env appTheme
  if (configuration('appTheme')) activeAppTheme = configuration('appTheme')

  // -- 檢查 API DashboardConfig.theme
  const env = process.env.NODE_ENV

  console.log('NODE_ENV', env)

  let host = []

  if (env === 'development') {
    host = ['apid', 'dashboard', 'ohbot', 'dev']
  } else if (env === 'e2e') {
    host = ['main-test-org', 'dashboard', 'ohbot', 'dev']
  } else {
    host = window.location.host.split('.') || []
  }

  const hostOrgCode = (host.length === 4 && host[0]) || ''

  if (hostOrgCode) {
    const res = await GetOrgsByCode({
      code: hostOrgCode,
    })
    store.commit('org/SET_ORG', res)
    store.dispatch('org/getUserInfoSetting', res.id)

    // -- 管考模式啟動時登入導轉頁面
    if (res.DashboardConfig?.enableRedirect) location = res.DashboardConfig.redirectURL

    if (res.DashboardConfig?.theme) activeAppTheme = res.DashboardConfig?.theme
  }

  // -- 檢查是否使用預設樣式
  if (!activeAppTheme) activeAppTheme = 'ohbot'

  // activeAppTheme = 'wishmobile' // LOCALDEV 強制更改後台色系 'ohbot' | 'beauty' | 'righttime' | 'wishmobile'

  changeCSS(activeAppTheme)
  store.commit('app/SET_APP_THEME', activeAppTheme)

  return activeAppTheme
}
