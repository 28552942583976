import Layout from '@/layout/index.vue'
import { EcommerceParameters } from './ecommerce'
import { MemberShopParameters } from './memberShop'
import { SalesParameters } from './sales'
import { MemberLevelParameters } from './memberLevel'

export const parametersRoutes = [
  {
    path: '/parameters',
    name: 'Parameters',
    component: Layout,
    redirect: '/parameter-settings',
    hidden: true,
    children: [
      {
        path: '/parameter/line-settings',
        name: 'LineSettings',
        component: () => import('@/views/Parameters/LineSettings/LineSettings.vue'),
        meta: {
          title: 'Line串接設定',
          action: 'admin.lineConfig.page',
          isParams: true,
        },
      },
      {
        path: '/parameter/member-center',
        name: 'MembersCenter',
        // hidden: true,
        component: () => import('@/views/Parameters/MemberCenter/MemberCenter'),
        meta: {
          title: '會員中心模組參數設定',
          action: 'admin.memberCenterConfig.page',
        },
      },
      // 預約
      {
        path: '/parameter/reservations/reservation-parameter',
        name: 'ReservationParameter',
        component: () =>
          import('@/views/Parameters/ReservationParameters/ReservationParameters'),
        meta: {
          title: '預約模組參數設定',
          action: 'admin.appointmentConfig.page',
        },
      },
      // {
      //   path: '/parameter/sales/sales-parameter',
      //   name: 'SalesParameter',
      //   component: () => import('@/views/Parameters/SalesParameters/SalesParameters.vue'),
      //   meta: {
      //     title: '銷售紀錄參數設定',
      //     action: 'admin.salesConfig.page',
      //   },
      // },
      {
        path: '/parameter/sales/receive-notify',
        name: 'ReceiveNotify',
        component: () => import('@/views/Parameters/ReceiveNotify/ReceiveNotify.vue'),
        meta: {
          title: '接收通知設定',
          action: 'admin.shopEmailNotify.find',
        },
      },
      {
        path: '/parameter/rebate/rebate-settings',
        component: () => import('@/views/Parameters/Rebate/RebateSettings.vue'),
        meta: {
          title: '回饋金參數設定',
          action: 'admin.shopCashback.page',
        },
      },
      {
        path: '/parameter/store/store-settings',
        name: 'StoreSetting',
        component: () => import('@/views/Parameters/Store/StoreSettings.vue'),
        meta: {
          title: '門市設定',
          action: 'admin.branch.page',
        },
      },
      {
        path: '/parameter/ticket/ticket-settings',
        name: 'TicketSetting',
        component: () => import('@/views/Parameters/Ticket/TicketSettings.vue'),
        meta: {
          title: '堂票模組參數設定',
          action: 'admin.classTicketConfig.page',
        },
      },
      {
        path: '/parameter/point/point-settings',
        name: 'PointSetting',
        component: () => import('@/views/Parameters/Point/PointSettings.vue'),
        meta: {
          title: '點數模組參數設定',
          action: 'admin.shopPoint.page',
        },
      },

      ...EcommerceParameters,
      ...MemberShopParameters,
      ...SalesParameters,
      {
        path: '/parameter/external-module-settings',
        name: 'ExternalModuleSettings',
        component: () => import('@/views/Parameters/ExternalModuleParameters/ExternalModuleParameters.vue'),
        meta: {
          title: '第三方交易模組設定',
          action: 'admin.memberExternalTransaction.page',
          isParams: true,
        },
      },
      {
        path: '/parameter/coupon-settings',
        name: 'CouponSettings',
        component: () => import('@/views/Parameters/Coupon/CouponSettings.vue'),
        meta: {
          title: '票券模組設定',
          action: 'admin.couponConfig.page',
        },
      },
      {
        path: '/parameter/reward-activity-settings',
        name: 'RewardActivitySettings',
        component: () => import('@/views/Parameters/RewardActivity/RewardActivitySettings.vue'),
        meta: {
          title: '序號登錄模組參數設定',
          action: 'admin.serialActivity.page',
        },
      },
      {
        path: '/parameter/external-integrate-module-settings',
        name: 'ExternalIntegrateModuleSettings',
        component: () => import('@/views/Parameters/ExternalIntegrateParameters/ExternalIntegrateParameters.vue'),
        meta: {
          title: '第三方串接模組設定',
          action: 'admin.shopCustomModule.posPalClientConfig',
          isParams: true,
        },
      },
      {
        path: '/parameter/resource-settings',
        name: 'ResourceSettings',
        component: () => import('@/views/Parameters/Resource/ResourceSettings.vue'),
        meta: {
          title: '設備模組參數設定',
          action: 'admin.resourceConfig.page',
        },
      },
      {
        path: '/parameter/shop-business-time-settings',
        name: 'ShopBusinessTimeSettings',
        component: () => import('@/views/Parameters/ShopBusinessTime/ShopBusinessTimeSettings.vue'),
        meta: {
          title: '營業時間設定',
          action: 'admin.shopBusinessTime.page',
        },
      },
      ...MemberLevelParameters,
      {
        path: '/parameter/tappay-settings',
        name: 'TapPaySettings',
        component: () => import('@/views/Parameters/TapPay/TapPay.vue'),
        meta: {
          title: 'iPhone 卡緊收參數設定',
          action: 'admin.tapPayConfig.page',
        },
      },
      {
        path: '/parameter/reservation/evaluate-settings',
        name: 'EvaluateSetting',
        component: () => import('@/views/Parameters/ReservationParameters/EvaluateParameters.vue'),
        meta: {
          title: '評價通知設定',
          action: 'admin.evaluateConfig.page',
        },
      },
    ],
  },
]
