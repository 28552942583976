<template>
  <div class="shop-feature-selector">
    <div class="overflow-y-auto" style="height: 300px">
      <el-checkbox-group v-model="enabledFeatures" class="flex flex-col" @change="onChange">
        <el-checkbox
          v-for="(value, key) in displayFeatures"
          :key="key"
          :label="key"
        />
      </el-checkbox-group>
    </div>
    <el-button block plain @click="reset">重置</el-button>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import store from '@/store'
import { omitBy, filter, pick, lowerCase } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ShopFeatureSelector',
  props: {
    search: String,
    testRoute: Boolean,
  },
  setup (props) {
    const { originShopPlanFeatures, computedShopPlanFeatures, refreshRoutes } = usePermissions()

    const enabledFeatures = ref([])

    const reset = () => {
      // store.commit('permission/SET_COMPUTED_PERMISSIONS', {
      //   key: 'shopPlanFeature', data: { ...originShopPlanFeatures.value },
      // })
      // const enabled = omitBy(originShopPlanFeatures.value, feature => !feature)
      // enabledFeatures.value = Object.keys(enabled)

      window.location.reload()
    }

    const displayFeatures = computed(() => {
      if (!props.search) return computedShopPlanFeatures.value
      const searchKey = lowerCase(props.search)
      const features = Object.keys(computedShopPlanFeatures.value)
      const matchs = filter(features, (feature) => lowerCase(feature).includes(searchKey))
      return pick(computedShopPlanFeatures.value, matchs)
    })

    const onChange = async (newValue) => {
      const features = computedShopPlanFeatures.value
      for (const key in features) {
        if (newValue.includes(key)) features[key] = true
        else features[key] = false
      }
      store.commit('permission/SET_COMPUTED_PERMISSIONS', {
        key: 'shopPlanFeature', data: features,
      })
      if (props.testRoute) await refreshRoutes()
    }

    onMounted(() => {
      const enabled = omitBy(originShopPlanFeatures.value, feature => !feature)
      enabledFeatures.value = Object.keys(enabled)
    })
    return { displayFeatures, enabledFeatures, onChange, reset, computedShopPlanFeatures }
  },
})
</script>

<style lang="postcss" scoped>
.shop-feature-selector {
  @apply h-full flex flex-col gap-[12px];
}
</style>
