<template>
  <div class="basic-tabs">
    <div class="tabs-list">
      <span
        v-for="tab in tabs"
        :key="tab.value"
        class="tab-item"
        :class="{active: value === tab.value}"
        @click="onClickTab(tab.value)"
      >
        {{ tab.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicTabs',
  props: {
    value: { required: true },
    tabs: { type: Array, required: true },
  },
  methods: {
    onClickTab (newTab) {
      this.$emit('change', newTab)
      this.$emit('input', newTab)
    },
  },
}
</script>

<style lang="scss" scoped>
.basic-tabs {
  .tab-item {
    @apply text-normal cursor-pointer text-info font-medium relative mr-[30px] inline-block;

    &.active {
      @apply text-primary-100;
    }
    &.active::after {
      @apply absolute top-full left-0;
      @apply w-full h-[4px] content-[""] bg-primary-100;
    }
  }
}
</style>
