<template>
  <div
    ref="targetRef"
    class="feature-key-debugger"
    :class="{ showing: toggle }"
    :style="style"
  >
    <div class="filters-container">
      <el-input v-if="toggle" v-model="search" clearable placeholder="搜尋 featureKey" />
      <el-checkbox v-if="toggle" v-model="testRoute">測試路由</el-checkbox>
      <el-button class="primary-btn" plain @click="toggle = !toggle">
        {{ toggle ? '關閉' : '權限控制' }}
      </el-button>
    </div>
    <div v-show="toggle">
      <el-tabs v-model="curTab" type="card">
        <el-tab-pane label="店家方案" />
        <el-tab-pane label="使用者權限" />
      </el-tabs>
    </div>

    <div v-show="toggle">
      <ShopFeatureSelector v-show="curTab === '0'" :search="search" :testRoute="testRoute" />
      <UserFeatureSelector v-show="curTab === '1'" :search="search" :testRoute="testRoute" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import ShopFeatureSelector from './components/ShopFeatureSelector.vue'
import UserFeatureSelector from './components/UserFeatureSelector.vue'
import { useDraggable, useWindowSize } from '@vueuse/core'

export default defineComponent({
  name: 'FeatureKeyDebugger',
  components: { ShopFeatureSelector, UserFeatureSelector },
  setup (props) {
    const targetRef = ref(null)
    const search = ref(null)
    const curTab = ref('')

    const toggle = ref(false)
    const testRoute = ref(false)

    const { width, height } = useWindowSize()
    const { x, y, style } = useDraggable(targetRef, {
      initialValue: { x: 0, y: 0 },
    })

    const resetPosition = () => {
      x.value = width.value - 200
      y.value = height.value - 100
      toggle.value = false
    }

    watch(width, () => {
      resetPosition()
    })
    watch(height, () => {
      resetPosition()
    })
    watch(toggle, (show) => {
      if (show) {
        setTimeout(() => {
          x.value = x.value - targetRef.value.clientWidth + 200
          y.value = y.value - targetRef.value.clientHeight + 100
        }, 0)
      } else {
        x.value = x.value + targetRef.value.clientWidth - 200
        y.value = y.value + targetRef.value.clientHeight - 100
      }
    })

    onMounted(() => {
      resetPosition()
    })

    return {
      targetRef,
      toggle,
      search,
      curTab,
      style,
      testRoute,
    }
  },
})
</script>

<style lang="postcss" scoped>
.feature-key-debugger {
    @apply fixed;
    @apply h-[fit-content] w-[fit-content];
    @apply p-[15px] rounded-[8px];
    @apply overflow-hidden z-[9999];
    @apply flex flex-col gap-[10px];
    @apply border-[3px] border-[#03fc5a] cursor-move;
    @apply transition duration-[1000] ease-in-out;
}

.showing {
  @apply bg-gray-300 bg-opacity-80;
  @apply transition duration-[1000] ease-in-out;
}
.filters-container {
  @apply flex items-center gap-[10px];
}
</style>
