import { admin2 } from '@/api/instance'

// ANCHOR Banner
export const GetBanner = async ({
  type,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: '/ohbotBanner',
    params: {
      type,
      start,
      limit,
    },
  })
}

export const GetBannerCount = async ({
  type,
}) => {
  return await admin2({
    method: 'get',
    url: '/ohbotBanner/count',
    params: {
      type,
    },
  })
}

export const FindBanner = async ({
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/ohbotBanner/${id}`,
  })
}

// ANCHOR FAQ
export const GetHelpQA = async ({
  question,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: '/helpQA',
    params: {
      question,
      start,
      limit,
    },
  })
}

export const GetHelpQACount = async ({
  question,
}) => {
  return await admin2({
    method: 'get',
    url: '/helpQA/count',
    params: {
      question,
    },
  })
}

export const FindHelpQA = async ({
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/helpQA/${id}`,
  })
}

// ANCHOR announcement
export const GetAnnouncement = async ({
  title,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: '/ohbotNews',
    params: {
      title,
      start,
      limit,
    },
  })
}

export const GetAnnouncementCount = async ({
  title,
}) => {
  return await admin2({
    method: 'get',
    url: '/ohbotNews/count',
    params: {
      title,
    },
  })
}

export const FindAnnouncement = async ({
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/ohbotNews/${id}`,
  })
}
// ANCHOR 教學手冊
export const GetHandbook = async ({
  name,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: '/helpFile',
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetHandBookCount = async ({
  name,
}) => {
  return await admin2({
    method: 'get',
    url: '/helpFile/count',
    params: {
      name,
    },
  })
}

export const FindHandBook = async ({
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/helpFile/${id}`,
  })
}

export const GetHandBookFileDownloadLink = async ({
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/helpFile/${id}/getFileSignedUrlExpireUrl`,
  })
}
