import Layout from '@/layout'

export const rewardActivityRoutes = [
  {
    path: '/reward-activity',
    component: Layout,
    name: 'RewardActivity',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '獎勵活動模組',
    },
    children: [
      {
        path: '',
        name: 'RewardActivityList',
        component: () => import('@/views/RewardActivity/RewardActivityList/RewardActivityList.vue'),
        meta: {
          title: '序號登錄活動',
          action: 'admin.serialActivity.page',
        },
      },
      {
        path: 'edit/:id?',
        name: 'RewardActivityEdit',
        component: () => import('@/views/RewardActivity/SerialActivityEdit/SerialActivityEdit.vue'),
        hidden: true,
        meta: {
          title: '編輯序號登錄活動',
          action: 'admin.serialActivity.page',
        },
      },
      {
        path: 'record/:id?',
        name: 'SerialActivityRewardRecord',
        component: () => import('@/views/RewardActivity/SerialActivityRewardRecord/SerialActivityRewardRecord.vue'),
        hidden: true,
        meta: {
          title: '獎勵紀錄',
          action: 'admin.serialActivity.page',
        },
      },
      {
        path: 'code-management/:id?',
        name: 'SerialCodeManagement',
        component: () => import('@/views/RewardActivity/SerialCodeManagement/SerialCodeManagement.vue'),
        hidden: true,
        meta: {
          title: '活動序號管理',
          action: 'admin.serialActivityCode.page',
        },
      },

    ],
  },
]
