import { admin2 } from '@/api/instance'

// => 建立活動
export const CreateActivity = async ({
  shopId,
  image,
  name,
  order,
  startAt,
  endAt,
  requirementLogic,
  isStorewideActivity,
  promoRequirements,
  promoContents,
  ecommerceProducts,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceActivity`,
    data: {
      image,
      name,
      order,
      startAt,
      endAt,
      requirementLogic,
      isStorewideActivity,
      promoRequirements,
      promoContents,
      ecommerceProducts,
    },
  })
  return res
}

// => 取得活動
export const GetActivity = async ({
  shopId,
  start,
  limit,
  name,
  enable,
  isStorewideActivity,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceActivity`,
    params: { start, limit, name, enable, isStorewideActivity },
  })
  return res
}

// => 取得指定活動
export const FindActivity = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceActivity/${id}`,
  })
  return res
}

// => 取得活動數量
export const GetActivityCount = async ({
  shopId,
  name,
  isStorewideActivity,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceActivity/count`,
    params: { name, isStorewideActivity },
  })
  return res
}

// => 更新活動
export const UpdateActivity = async ({
  shopId,
  id,
  image,
  order,
  name,
  startAt,
  endAt,
  requirementLogic,
  isStorewideActivity,
  promoRequirements,
  promoContents,
  ecommerceProducts,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceActivity/${id}`,
    data: {
      image,
      name,
      order,
      startAt,
      endAt,
      requirementLogic,
      isStorewideActivity,
      promoRequirements,
      promoContents,
      ecommerceProducts,
    },
  })
  return res
}

// => 刪除活動
export const DeleteActivity = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceActivity/${id}`,
  })
  return res
}
