// 會員商城
export const SalesParameters = [
  {
    path: '/parameter/sales/sales-parameter',
    name: 'SalesParameterSetting',
    component: () => import('@/views/Parameters/SalesParameters/SalesParameters.vue'),
    meta: {
      title: '銷售紀錄參數設定',
      // action: 'admin.ecommerceConfig.page',
    },
    children: [
      // ---- 顯示用 routes ----
      {
        path: '/sales-module-settings',
        redirect: '/parameter/sales/sales-module-setting',
        meta: {
          title: '銷售模組後台設定',
          action: 'admin.salesConfig.page',
        },
      },
      {
        path: '/external-module-settings',
        redirect: '/parameter/sales/external-module-settings',
        meta: {
          title: '客人消費登錄設定',
          action: 'admin.salesClientConfig.page',
        },
      },
    ],
  },
  // ---- 實際 routes ----
  {
    path: '/parameter/sales/sales-module-setting',
    name: 'SalesModuleSettings',
    hidden: true,
    component: () => import('@/views/Parameters/SalesParameters/components/SalesModuleSettings.vue'),
    meta: {
      title: '銷售模組後台設定',
      action: 'admin.salesConfig.page',
    },
  },
  {
    path: '/parameter/sales/external-module-settings',
    name: 'ExternalModuleSettings',
    hidden: true,
    component: () => import('@/views/Parameters/SalesParameters/components/ManualSalesRecordSettings/ManualSalesRecordSettings.vue'),
    meta: {
      title: '客人消費登錄設定',
      action: 'admin.salesClientConfig.page',
      isParams: true,
    },
  },
]
