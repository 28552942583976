<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
          (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
          !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <div class="flex justify-between items-center h-full">
            <item
              :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
              :title="onlyOneChild.meta.title"
            />
            <Tag v-if="onlyOneChild.name === 'HomePage' && haveUnreadAnnouncements" type="danger">New</Tag>
          </div>
        </el-menu-item>
      </app-link>
    </template>

    <el-menu-item-group
      v-else-if="item.isGroup"
      class="p-0"
      :index="resolvePath(item.path)"
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-menu-item-group>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-submenu>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import path from 'path'
import Item from './Item'
import AppLink from './Link'

export default defineComponent({
  name: 'SidebarItem',
  components: {
    Item,
    AppLink,
  },
  props: {
    haveUnreadAnnouncements: Boolean,
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data () {
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {
      isActive: false,
    }
  },
  watch: {
    $route: 'activeSubPath',
  },
  mounted () {
    this.activeSubPath()
  },
  methods: {
    hasOneShowingChild (children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      return path.resolve(this.basePath, routePath)
    },
    activeSubPath () {
      if (this.onlyOneChild) {
        const childPath = this.resolvePath(this.onlyOneChild.path)
        this.isActive = this.$route.path.indexOf(childPath) >= 0
      }
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-menu-item {
  @apply py-[6px] h-[36px] rounded-sm text-normal leading-[16px];
  // line-height: unset;
}

.el-menu-item:hover {
  @apply bg-primary-100 text-white h-[36px] font-bold leading-[16px];
}

::v-deep .el-menu-item.is-active {
  @apply bg-primary-100 text-white font-bold leading-[16px];
}

::v-deep .el-menu-item-group__title {
  @apply text-primary-100 font-bold text-sub;
}

// submenu
.el-submenu .el-menu-item {
  @apply py-[6px] h-[36px] rounded-sm leading-[16px];
}

::v-deep .el-submenu__title {
  @apply h-[36px] flex items-center rounded-sm text-normal ;
  line-height: unset;
}

::v-deep .el-submenu__title:hover {
  @apply bg-primary-100 text-white font-bold;
}

.menu-wrapper {
  @apply mb-[8px];
}
::v-deep .el-menu--inline {
  @apply mt-[8px];
}
</style>
