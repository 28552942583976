// 會員商城
export const MemberLevelParameters = [
  {
    path: '/parameter/member-level',
    name: 'MemberLevelSettings',
    component: () => import('@/views/Parameters/MemberLevelSettings/MemberLevelSettings.vue'),
    meta: {
      title: '會員等級參數設定',
      action: 'admin.memberLevelConfig.page',
    },
  },
]
