export const titleConfig = {
  beauty: '美業歐巴後台管理',
  ohbot: '思言科技後台管理',
  righttime: '健康綠幣後台管理',
  wishmobile: '幸福接送後台管理',
  salesOhbot: '快銷歐巴後台管理',
  xuanien: '運動歐巴後台管理',
}

export const faviconConfig = {
  beauty: '/favicon-beauty.ico',
  ohbot: '/favicon-ohbot.ico',
  righttime: '/favicon-righttime.jpg',
  wishmobile: '/favicon-wishmobile.png',
  salesOhbot: '/favicon-wishmobile.png',
  xuanien: '/favicon-xuanien.png',
}
