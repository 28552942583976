// 電商
export const EcommerceParameters = [
  {
    path: '/parameter/ecommerce/parameter-setting',
    name: 'EcommerceParameterSetting',
    component: () => import('@/views/Parameters/EcommerceParameters/EcommerceParameters.vue'),
    meta: {
      title: '電商模組參數設定',
      action: 'admin.ecommerceConfig.page',
    },
    children: [
      {
        path: '/common-settings',
        redirect: '/parameter/ecommerce/common-settings',
        meta: {
          title: '基本設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
      {
        path: '/link-settings',
        redirect: '/parameter/ecommerce/link-settings',
        meta: {
          title: '連結設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
      {
        path: '/invoice-settings',
        redirect: '/parameter/ecommerce/invoice-settings',
        meta: {
          title: '發票設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
      {
        path: '/payment-settings',
        redirect: '/parameter/ecommerce/payment-settings',
        meta: {
          title: '付款設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
      {
        path: '/delivery-settings',
        redirect: '/parameter/ecommerce/delivery-settings',
        meta: {
          title: '運送設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
      {
        path: '/analysis-settings',
        redirect: '/parameter/ecommerce/analysis-settings',
        meta: {
          title: '追蹤工具設定',
          action: 'admin.ecommerceConfig.page',
        },
      },
    ],
  },
  {
    path: '/parameter/ecommerce/common-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/CommonSettings.vue'),
    name: 'CommonSettings',
    hidden: true,
    meta: {
      title: '基本設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
  {
    path: '/parameter/ecommerce/link-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/LinkSettings.vue'),
    name: 'LinkSettings',
    hidden: true,
    meta: {
      title: '連結設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
  {
    path: '/parameter/ecommerce/invoice-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/InvoiceSettings.vue'),
    name: 'InvoiceSettings',
    hidden: true,
    meta: {
      title: '發票設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
  {
    path: '/parameter/ecommerce/payment-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/PaymentSetting.vue'),
    name: 'PaymentSettings',
    hidden: true,
    meta: {
      title: '付款設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
  {
    path: '/parameter/ecommerce/delivery-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/DeliverySetting.vue'),
    name: 'DeliverySettings',
    hidden: true,
    meta: {
      title: '運送設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
  {
    path: '/parameter/ecommerce/analysis-settings',
    component: () => import('@/views/Parameters/EcommerceParameters/components/AnalysisSettings.vue'),
    name: 'AnalysisSettings',
    hidden: true,
    meta: {
      title: '追蹤工具設定',
      action: 'admin.ecommerceConfig.page',
    },
  },
]
