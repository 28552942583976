module.exports = {
  feature: {
    Basic: 'Basic',
    Member: 'Member',
    Marketing: 'Marketing',
    Appointment: 'Appointment',
    Sales: 'Sales',
    ClassTicket: 'ClassTicket',
    Ecommerce: 'Ecommerce',
  },
  featurePermission: [{
    feature: 'appointmentConfig',
    actions: [{
      action: 'findOne',
      label: 'admin.appointmentConfig.findOne',
    }],
  }, {
    feature: 'Appointment',
    actions: [{
      action: 'find',
      label: 'admin.appointmentCategory.find',
    }, {
      action: 'create',
      label: 'admin.appointmentCategory.create',
    }, {
      action: 'update',
      label: 'admin.appointmentCategory.update',
    }, {
      action: 'destroy',
      label: 'admin.appointmentCategory.destroy',
    }],
  }],
}
