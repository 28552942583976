<template>
  <div
    class="table-filters-container"
    :class="{
      'flex-layout': flat,
      'grid-layout': !flat
    }"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FiltersContainer',
  props: {
    flat: { type: Boolean, default: false },
  },
})
</script>

<style lang="postcss" scoped>
.table-filters-container {
  @apply mb-[20px] gap-[12px];
}

.flex-layout {
  @apply flex flex-wrap ;
}

.grid-layout {
  @apply grid items-center;
  grid-template-columns: 340px 340px;
}

::v-deep .el-input{
  @apply w-[340px];
}
::v-deep .el-select{
  @apply w-[340px];
}
</style>
