<template>
  <div class="suspend-notice">
    <SVGIcon
      class="notification"
      icon="memberCenter-notification"
      stroke="transparent"
      fill="var(--warning)"
    />
    <p class="title">{{ title }}</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuspendNotice',
  props: {
    title: {
      type: String, default: () => '更版停機公告',
    },
  },
  setup (props, { emit }) {
    return { }
  },
})
</script>

  <style scoped lang="postcss">
  .suspend-notice {
      @apply flex flex-row items-center py-[5px]
  }
  .suspend-notice .notification {
    @apply mr-[8.67px]
  }
  .suspend-notice .title {
    @apply !text-[14px] font-medium !leading-[22px] !tracking-[0.14px] text-base
  }
  </style>
