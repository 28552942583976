import Layout from '@/layout'

export const couponRoutes = [
  // 使用券模組
  {
    path: '/coupon',
    component: Layout,
    name: 'Coupon',
    // hidden: !(configuration('ecommerceModule') === 'true'),
    // hidden: true,
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '票券模組',
    },
    children: [
      {
        path: '/coupon-setting',
        name: 'CouponSetting',
        component: () => import('@/views/Lottery/CouponSetting'),
        meta: {
          title: '使用券設定',
          action: 'admin.coupon.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_COUPON_LIST',
        },
      },
      {
        path: '/coupon-create/:id?',
        name: 'CouponCreate',
        hidden: true,
        component: () => import('@/views/Lottery/CouponCreate'),
        meta: {
          title: '使用券編輯',
          action: 'admin.coupon.create',
        },
      },
      {
        path: '/coupon-exchange-setting',
        name: 'CouponExchangeSetting',
        component: () => import('@/views/Coupon/CouponExchangeSetting/CouponExchangeSetting.vue'),
        meta: {
          title: '兌換券設定',
          action: 'admin.couponExchange.page',
        },
      },
      {
        path: '/coupon-exchange/:id?',
        name: 'CouponExchangeCreate',
        hidden: true,
        component: () => import('@/views/Coupon/CouponExchangeEdit/CouponExchangeEdit.vue'),
        meta: {
          title: '兌換券編輯',
          action: 'admin.couponExchange.create',
        },
      },
      {
        path: '/coupon-exchange-serial/:id?',
        name: 'CouponExchangeSerial',
        hidden: true,
        component: () => import('@/views/Coupon/CouponExchangeSerial/CouponExchangeSerial.vue'),
        meta: {
          title: '兌換碼管理',
          action: 'admin.couponExchange.page',
        },
      },
      {
        path: '/coupon-usage-record',
        name: 'CouponUsageRecord',
        component: () => import('@/views/Coupon/CouponUsageRecord/CouponUsageRecord.vue'),
        meta: {
          title: '票券使用紀錄',
          action: 'admin.couponRecord.page',
        },
      },
    ],
  },
]
