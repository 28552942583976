import Layout from '@/layout'

export const rewardPostponeRoutes = [
  {
    path: '/reward-postpone',
    component: Layout,
    name: 'RewardPostpone',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '獎勵展延模組',
    },
    children: [
      {
        path: 'reward-postpone-list',
        name: 'RewardPostponeList',
        component: () => import('@/views/RewardPostponeList/RewardPostponeList.vue'),
        meta: {
          title: '獎勵展延列表',
          action: 'admin.memberBatchTask.page',
        },
      },
      {
        path: 'reward-postpone-edit',
        name: 'RewardPostponeEdit',
        hidden: true,
        component: () => import('@/views/RewardPostponeEdit/RewardPostponeEdit.vue'),
        meta: {
          title: '獎勵展延設定',
          action: 'admin.memberBatchTask.page',
        },
      },
    ],
  },
]
