import { admin2 } from '@/api/instance'

// => 建立產品類別
export const CreateProductCategory = async ({
  shopId,
  image,
  name,
  order,
  isPublic,
  ecommerceProducts,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceCategory`,
    data: {
      image,
      name,
      order,
      isPublic,
      ecommerceProducts,
    },
  })
  return res
}

// => 取得產品類別
export const GetProductCategory = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategory`,
    params: {
      start,
      limit,
      name,
    },
  })
  return res
}

// => 取得產品類別數量
export const GetProductCategoryCount = async ({
  shopId,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategory/count`,
    params: { name },
  })
  return res
}

// => 取得特定產品類別
export const FindProductCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategory/${id}`,
  })
  return res
}

// => 更新產品類別
export const UpdateProductCategory = async ({
  shopId,
  id,
  image,
  order,
  name,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceCategory/${id}`,
    data: {
      image,
      name,
      order,
    },
  })
  return res
}

// => 刪除特定產品
export const DeleteProductCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceCategory/${id}`,
  })
  return res
}
