<template>
  <div class="lauout">
    <Headbar />
    <div v-loading="!loaded" class="main-view">
      <Sidebar v-if="loaded" :class="{ openSideBar: sidebar }" />
      <div v-if="loaded" id="viewContainer" ref="viewContainer" class="overflow-y-auto w-full border-none" style="padding: 30px">
        <router-view style="padding-bottom: 100px" />
      </div>
    </div>
  </div>
</template>

<script>
import { Sidebar, Headbar } from '@/layout/components'
export default {
  name: 'Layout',
  components: {
    Headbar,
    Sidebar,
  },
  data: () => ({
    loaded: false,
  }),
  computed: {
    sidebar () {
      return this.$store.getters.sidebar
    },

    isMobile () {
      return this.$store.state.app.isMobile
    },
  },
  watch: {
    $route () {
      if (this.isMobile) this.$store.dispatch('app/setSidebar', false)
      setTimeout(() => {
        const elem = document.getElementById('viewContainer')
        elem.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 300)
    },
  },

  mounted () {
    setTimeout(() => {
      this.loaded = true
    }, 300)
  },
}
</script>

<style scoped lang="scss">
.lauout {
  @apply bg-[#F7F8FC] flex flex-col h-full;
}

.main-view {
  @apply flex flex-1 overflow-hidden;
}

@media screen and (min-width: 1200px) {
  .openSideBar {
    @apply w-[260px] flex-shrink-0;
    transition: ease-in-out 0.3s;
  }
}

@media screen and (max-width: 1200px) {
  .openSideBar {
    @apply absolute left-0 top-[80px] w-[260px] bottom-0 flex-shrink-0 z-[500];
    transition: ease-in-out 0.3s;
  }
}

// .openSideBar {
//   @apply w-[260px] flex-shrink-0;
//   transition: ease-in-out 0.3s;
// }
</style>
